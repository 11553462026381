import UserRow from "components/userRow/userRow";
import styles from "./userList.module.scss";
import { useEffect, useState } from "react";
import { getTournamentUsers } from "api/users";
import InfiniteScroll from "react-infinite-scroll-component";

const UserList = ({ id }: any) => {
  const [users, setUsers] = useState<any>([]);
  const [dataLength, setDataLength] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [page, setPage] = useState(1);
  const MINUTE_MS = 10000;
  useEffect(() => {
    const interval = setInterval(() => {
      setUsers([])
      getUsersByTournament();
    }, MINUTE_MS);
    return () => clearInterval(interval)
  }, []);

  const getUsersByTournament = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await getTournamentUsers(id, page, 10);
      setUsers((prevItems: any) => [...prevItems, ...response.userList]);
      console.log(users.length);
      setPage((prevPage) => prevPage + 1);
      setDataLength(response.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <InfiniteScroll
      dataLength={10}
      next={getUsersByTournament}
      hasMore={page >= dataLength ? false : true} // Replace with a condition based on your data source
      loader={""}
      endMessage={""}
    >
      <section className={styles.userListSection}>
        {users &&
          (users.length > 1
            ? users.map((user: any, i: number) => {
                return (
                  <UserRow
                    key={i}
                    position={i+1} 
                    nickname={user?.nickname}
                    score={user?.score}
                    country={user.country?.code2}
                    tournamentName={user?.tournament?.name}
                    img={user?.avatar2D}
                  ></UserRow>
                );
              })
            : users.length === 1 && (
                <UserRow
                  position={users[0]?.position}
                  nickname={users[0]?.nickname}
                  score={users[0]?.score}
                  country={users[0]?.country?.code2}
                  tournamentName={users[0]?.tournament.name}
                  img={users[0]?.avatar2D}
                ></UserRow>
              ))}
      </section>
    </InfiniteScroll>
  );
};
export default UserList;
