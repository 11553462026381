import TopBanner from "./components/topBanner/topBanner";
import ChampionshipList from "./components/championshipList/championshipList";

const Championship = () => {
  return (
    <div style={{margin: 'auto', maxWidth: '1920px'}}>
      <TopBanner></TopBanner>
      <ChampionshipList></ChampionshipList>
    </div>
  );
};
export default Championship;
