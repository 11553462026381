import { useParams } from "react-router-dom";
import TopUsers from "./components/topUsers/topUsers";
import UserList from "./components/userList/userList";

const LeaderBoard = () => {
  const params = useParams();
  return (
    <>
      <TopUsers id={params.id}></TopUsers>
      <UserList id={params.id}></UserList>
    </>
  );
};
export default LeaderBoard;
