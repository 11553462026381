import client from "./client";

export function getChampionship(


) {

  return client
    .get(`/Wallets/RankingPoints/Leaderboard`)
    
  
}
