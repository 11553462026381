import LeaderBoard from "view/leaderBoard";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Championship from "view/Championship/championship";
// import './App.scss';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/:id" element={<LeaderBoard />} />
          <Route path="championship" element={<Championship />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

// export default function App() {
//   return (
//     <>
//     <div className="App">
//     <header className="App-header">
// <p>ghj</p>

//     </header>
//   </div>
//   </>
//   )
// }
