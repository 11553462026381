import styles from "./userRow.module.scss";

type Props = {
  position: number;
  nickname: string;
  score: number;
  country: string;
  tournamentName: string;
  img: string;
};
const userRow = ({
  position,
  nickname,
  score,
  country,
  tournamentName,
  img,
}: Props) => {
  return (
    <div className={styles.userRowSection}>
      <span className={styles.ranking}>
        <b># {position}</b>
      </span>

      <span className={styles.flag}>
        {country && (
          <img
            className={styles.flag}
            alt="code2"
            src={`/Flags/${country}.png`}
          />
        )}
      </span>

      <span className={styles.img}>
        {" "}
        <img
          className={styles.img}
          alt="prof pic"
          src={
            img
              ? `https://api.mesl.eu/api/v1/Files/${img}/Image`
              : "/default.png"
          }
        ></img>
      </span>
      <span className={styles.name}>
        {" "}
        <b>{nickname}</b>
      </span>
      <span className={styles.score}>
        {" "}
        <b>{score}</b>
      </span>
    </div>
  );
};

export default userRow;
