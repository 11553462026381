import axios from 'axios';

export const BASE_API_URL = process.env.REACT_APP_BASE_URL + '/api';

const client = axios.create({
    // withCredentials: true,
    baseURL: 'https://api.mesl.eu/api/v1',
});

export default client;



