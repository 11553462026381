import client from "./client";

export function getTournamentUsers(
  id: any,
  pageNumber?: number,
  pageSize?: number
) {
  let query = "";
  if (pageNumber) {
    query = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
  }

  return client
    .get(`/Tournaments/${id}/Leaderboard/${query}`)
    .then((response: any) => {
      const mypagination = response?.headers.get("X-Pagination");
      var jsonMypagination = JSON.parse(mypagination);
      var totalPages = jsonMypagination.totalPages;
      const respData = {
        userList: response.data,
        totalPages: totalPages,
      };
      return respData;
    });
}
