import { getTournamentUsers } from "api/users";
import User from "../../../components/user/user";
import styles from './topUsers.module.scss';
import { useEffect, useState } from "react";

const TopUsers = ({ id }: any) => {
  const [tournamentName, setTournamentName] = useState<string>("");
  const [users, setUsers] = useState<any>([]);
  const MINUTE_MS = 10000;
  useEffect(() => {
    const interval = setInterval(() => {
      setUsers([]);
      getTournamentName();
    }, MINUTE_MS);
    return () => clearInterval(interval)
  }, []);

  const getTournamentName = async () => {
    const usersData = await getTournamentUsers(id, 1, 3);
    setUsers(usersData.userList);
    setTournamentName(usersData.userList[0]?.tournament?.name);
  };

  const Children = ({ name, value }: any) => {
    return (
      <>
        <p>
          <b>{name}</b>{" "}
        </p>
        {/* <p>
          <b>$ {value}</b>
        </p> */}
      </>
    );
  };
  return (
    <section className={styles.topUsersSection}>
      <h1 style={{color:'#FF69B4'}}>{tournamentName}</h1>
      <h3 style={{color:'#FF69B4'}}>Leaderboard</h3>
      {users.length ? (
        <div className={styles.users}>
          {users[1] && (
            <User
              color="#00FFFF"
              width="80px"
              height="80px"
              style={false}
              shadow={false}
              rank={2}
              img={
                users[1]?.avatar2D
                  ? `https://api.mesl.eu/api/v1/Files/${users[1]?.avatar2D}/Image`
                  : "https://cdn-icons-png.flaticon.com/512/1484/1484867.png"
              }
            >
              <Children name={users[1]?.nickname} value={users[1]?.coins} />
            </User>
          )}
          {users[0] && (
            <User 
              color="purple"
              width="120px"
              height="120px"
              rank={1}
              img={
                users[0]?.avatar2D
                  ? `https://api.mesl.eu/api/v1/Files/${users[0]?.avatar2D}/Image`
                  : "/default.png"
              }
            >
              <Children name={users[0]?.nickname} value={users[0]?.coins} />
            </User>
          )}
          {users[2] && (
            <User
              color="blue"
              width="80px"
              height="80px"
              style={false}
              rank={3}
              img={
                users[2]?.avatar2D
                  ? `https://api.mesl.eu/api/v1/Files/${users[2]?.avatar2D}/Image.jpg`
                  : "https://cdn-icons-png.flaticon.com/512/1484/1484867.png"
              }
            >
              <Children name={users[2]?.nickname} value={users[2]?.coins} />
            </User>
          )}
        </div>
      ) : (
        <h1>Play To Win!</h1>
      )}
    </section>
  );
};

export default TopUsers;
