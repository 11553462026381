import { getTournamentUsers } from "api/users";
import User from "./../../../../components/user/user";
import styles from "./topBanner.module.scss";
import { useEffect, useState } from "react";
import rp from "./../../../../assets/rp.jpg";
import me from "./../../../../assets/me.png";
import championship from "./../../../../assets/championship.png";
import trophy from "./../../../../assets/trophy.jpg";
import { getChampionship } from "api/championship";
import defaultPic from "./../../../../assets/DefAvatar.png";
import coin from "./../../../../assets/Coin.png";

const MINUTE_MS = 60000;

const TopBanner = ({ id }: any) => {
  const [users, setUsers] = useState<any>([]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      setUsers([]);
      fetchData();
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    try {
      const response = await getChampionship();
      setUsers((prevItems: any) => [...prevItems, ...response.data]);
      console.log('fav points', response.data[0].points)
    } catch (error) {
      console.log(error);
    }
  };

  const Children = ({ name, points }: any) => {
    console.log('pointsss', points)
    return (
      <>
        <p>
          <b>{name}</b>
        </p>
        <p>
          <b>
            {" "}
            <img src={coin} style={{ width: "10%" }} /> {points}
          </b>
        </p>
      </>
    );
  };
  return (
    <section className={styles.topBannerSection}>
      {/* {users.length ? ( */}
      <img
        src={rp}
        style={{ width: "50%", objectFit: "cover", height: "100%" }}
      />
      <img
        src={me}
        style={{ width: "50%", objectFit: "cover", height: "100%" }}
      />

      <div className={styles.banner}>
        <div>
          <div>
          <img
            src={championship}
            className={styles.championship}
          /></div>

          <img
            src={trophy}
            style={{
              paddingTop: "20px",
              backgroundColor: "white",
              width: "30%",
              objectFit: "contain",
              position: "absolute",
              // width: '50px',
              height: "80px",
              // backgroundColor: "blueviolet",
              left: "35%",
              top: "36%",
              zIndex: "1",
            }}
          />
        </div>
        <div
          className={styles.users}
          style={{
            position: "absolute",
            width: "30%",
            height: "200px",
            left: "35%",
            top: "60%",
            zIndex: "1",
          }}
        >
          {users[1] && (
            <User
              color="gray"
              width="80px"
              height="80px"
              style={false}
              shadow={false}
              rank={2}
              img={
                users[1]?.avatar2D
                  ? `https://api.mesl.eu/api/v1/Files/${users[1]?.avatar2D}/Image`
                  : defaultPic
              }
            >
              <Children name={users[1]?.nickname} points={users[1]?.points} />
            </User>
          )}
          {users[0] && (
            <User
              color="#FFD700"
              width="120px"
              height="120px"
              rank={1}
              img={
                users[0]?.avatar2D
                  ? `https://api.mesl.eu/api/v1/Files/${users[0]?.avatar2D}/Image`
                  : defaultPic
              }
            >
              <Children name={users[0]?.nickname} points={users[0]?.points} />
            </User>
          )}
          {users[2] && (
            <User
              color="orange"
              width="80px"
              height="80px"
              style={false}
              rank={3}
              img={
                users[2]?.avatar2D
                  ? `https://api.mesl.eu/api/v1/Files/${users[2]?.avatar2D}/Image.jpg`
                  : defaultPic
              }
            >
              <Children name={users[2]?.nickname} points={users[2]?.points} />
            </User>
          )}
        </div>
      </div>
    </section>
  );
};

export default TopBanner;
