import styles from "./user.module.scss";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import cn from "classnames";
import { ReactComponent as TrophyIcon } from '../../assets/award_trophy_winner_prize_icon_208897.svg';

type Props = {
  color: string;
  width: string;
  height: string;
  style?: boolean;
  shadow?: boolean;
  children?: any;
  rank: number;
  img?: string;
};

const User = ({ color, width, height, style, shadow, children, rank, img }: Props) => {
  return (
    <>
      <div className={styles.userSection}>
      <TrophyIcon fill={color} width='24px' height='24px'></TrophyIcon>
        <div
          className={cn(styles.ImagePart, { [styles.box]: style !== false })}
          style={{
            borderColor: color,
            boxShadow: shadow !== false ? `8px 0 10px -2px ${color}` : "",
            width: width,
            height: height,
            position: 'relative'
          }}
        >
          
          <img
            className={styles.img}
            alt="hk"
            src={img}
          ></img>
          <div
            className={styles.rankingNumber}
            style={{ backgroundColor: color }}
          >
            {rank}
          </div>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className={styles.children}>
        {children !== undefined && children}
        </div>
      </div>
    </>
  );
};
export default User;
