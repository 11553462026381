import ChampionshipRow from "components/championshipRow/championshipRow";
import styles from "./championshipList.module.scss";
import { useEffect, useState } from "react";
import { getChampionship } from "api/championship";

const MINUTE_MS = 60000;

const ChampionshipList = ({ id }: any) => {
  const [users, setUsers] = useState<any>([]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      setUsers([]);
      fetchData();
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    try {
      const response = await getChampionship();
      setUsers((prevItems: any) => [...prevItems, ...response.data]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className={styles.userListSection}>
      {users &&
        (users.length > 1
          ? users.map((user: any, i: number) => {
              return (
                <ChampionshipRow
                  key={i}
                  position={i + 1}
                  nickname={user?.nickname}
                  points={user.points}
                  amount={user?.amount}
                  country={user.country?.code2}
                  tournamentName={user?.tournament?.name}
                  img={user?.avatar2D}
                ></ChampionshipRow>
              );
            })
          : users.length === 1 && (
              <ChampionshipRow
                position={users[0]?.position}
                nickname={users[0]?.nickname}
                points={users[0]?.points}
                amount={users[0]?.amount}
                country={users[0]?.country?.code2}
                tournamentName={users[0]?.tournament.name}
                img={users[0]?.avatar2D}
              ></ChampionshipRow>
            ))}
    </section>
  );
};
export default ChampionshipList;
